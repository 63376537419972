<template>
  <secondary-page-layout title="Вход" :backBtn="false">
    <div class="registration">
      <ValidationObserver ref="form" v-slot="{invalid}">
        <div class="form-inner">
          <div class="form-group">
            <input-field
                label="Номер телефона"
                v-model="form.phone_number"
                field-type="tel"
                :is-block="true"
                rules="required|min:10"
                name="phone_number"
                mask="+7(###) ###-##-##"/>
            <input-field
                label="Пароль"
                field-type="password"
                rules="required|min:8"
                :is-block="true"
                name="password"
                addition="*Пароль должен содержать минимум 8 символов"
                v-model="form.password"/>
            <router-link to="/reset-password" style="font-size: 14px; color: #2aa65c; float: right">Забыли пароль?</router-link>
          </div>
          <v-dialog class="error"/>
          <div class="buttons-wrapper">
            <button class="primary-btn" :disabled="invalid" @click="onSubmit">Войти</button>
            <button class="bordered-btn" @click="goToRegister">Зарегистрироваться</button>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </secondary-page-layout>
</template>

<script>
import {mapActions} from "vuex";
import InputField from "../../components/BuyPolicy/InputField";
import eventLogger from '../../services/eventLogger'

export default {
  name: "SingIn",
  components: {
    InputField
  },
  data() {
    return {
      form: {
        phone_number: '+7(',
        password: '',
        token: '',
      },
      error: null,
    }
  },
  mounted(){
    eventLogger('view_login_screen')
  },
  methods: {
    ...mapActions(['signIn']),
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.login()
        }
      });
    },
    async login() {
      let loader = this.$loading.show({});

      try {
        this.form.token = window.DEVICE_ID
        await this.signIn(this.form);
        eventLogger('login_success')
        this.error = null;
        window.location.reload();
      } catch (e) {
        const {data, status} = e.response;
        eventLogger('login_fail', data?.message || 'Неизвестная ошибка')
        if (status === 401) {
          this.$modal.show('dialog', {
            title: 'Ошибка',
            text: 'Введен неверный пароль',
            buttons: [
              {
                title: 'Закрыть',
                handler: () => {
                  this.$modal.hide('dialog')
                }
              },
            ]
          });
        } else if (status === 422) {
          this.$modal.show('dialog', {
            title: 'Ошибка',
            text: 'Не удалось найти аккаунт',
            buttons: [
              {
                title: 'Закрыть',
                handler: () => {
                  this.$modal.hide('dialog')
                }
              },
            ]
          });
        }
      } finally {
        loader.hide();
      }
    },
    goToRegister() {
      this.$router.push('/register')
    },
  },
}


window.setDeviceId = function(DEVICE_ID) {
  window.DEVICE_ID = DEVICE_ID
}
</script>

<style lang="scss" scoped>
.registration {
  height: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  .title {
    font-weight: bold;
    text-align: center;
    margin-bottom: 36px;
  }
  .form-group {
    .primary-block {
      padding: 24px;
    }
    .secondary-text {
      font-size: 14px;
    }
  }
}

.form-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.buttons-wrapper {
  width: 100%;
  padding: 16px;
  .primary-btn {
    width: 100%;
    margin-bottom: 12px;
  }
  .bordered-btn {
    width: 100%;
  }
}
</style>
